import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Work, Projects, and Tech Stacks`}</h2>
    <ProjectCard title="Rumah123.com" link="https://www.rumah123.com/" bg="linear-gradient(to right, #327dfc 30%, #dde6eb 120%)" mdxType="ProjectCard">
  <img src="gopher.svg.png" alt="gopher" style={{
        "marginRight": "10px"
      }} width="75" height="75" />
  <img src="graphql.png" alt="gopher" style={{
        "marginRight": "10px"
      }} width="75" height="75" />
  <img src="elastic.png" alt="elastic" style={{
        "marginRight": "10px"
      }} width="75" height="75" />
  <img src="kafka.png" alt="kafka" style={{
        "marginRight": "10px"
      }} width="75" height="75" />
  <img src="kibana.png" alt="kibana" style={{
        "marginRight": "10px"
      }} width="75" height="75" />
  <br></br>
  <br></br>
  Rumah123.com is a property listing platform used by Independent Property Agent,
  Developers, and Property searchers.
    </ProjectCard>
    <ProjectCard title="SCIF system" link="http://www.bringinlife.co.id/" bg="linear-gradient(to right, #1961d4 0%, #052e6e 100%)" mdxType="ProjectCard">
  <img src="node.jpg" alt="Node.js" style={{
        "marginRight": "10px"
      }} width="100" height="100" />
  <img src="sencha.png" alt="Sencha Framework" style={{
        "marginRight": "10px"
      }} width="100" height="100" />
  <img src="mssql.png" alt="MS SQL" style={{
        "marginRight": "10px"
      }} width="100" height="100" />
  <br></br>
  SCIF is an identification system of a digital insurance for Asuransi BRI
    </ProjectCard>
    <ProjectCard title="Medico" link="https://medico.id/" bg="linear-gradient(to right, #9c0000 0%, #ffffff 120%)" mdxType="ProjectCard">
  <img src="csharp.png" alt="C#" style={{
        "marginRight": "10px"
      }} width="75" height="75" />
  <img src="netcore.png" alt=".NET Core" style={{
        "marginRight": "10px"
      }} width="75" height="75" />
  <img src="mariadb.png" alt="MariaDB" style={{
        "marginRight": "10px"
      }} width="75" height="75" />
  <img src="rabbitmq.png" alt="RabbitMQ" style={{
        "marginRight": "10px"
      }} width="75" height="75" />
  <img src="docker.png" alt="Docker" style={{
        "marginRight": "10px"
      }} width="75" height="75" />
  <br></br>
  <br></br>
  Medico is a cloud Hospital Information System service that aims to unify nation's
  healthcare system. By using this system, Patient's Medical Record is accessible
  by any registered hospital, hence easing Patient and Healthcare workers.
    </ProjectCard>
    <ProjectCard title="EProcPPN" link="https://apps.patraniaga.com/eprocppn/" bg="linear-gradient(to right, #0054db 0%, #cc1010 100%)" mdxType="ProjectCard">
  <img src="angular.png" alt="Angular" style={{
        "marginRight": "10px"
      }} width="100" height="100" />
  <img src="csharp.png" alt="C#" style={{
        "marginRight": "10px"
      }} width="100" height="100" />
  <img src="netcore.png" alt=".NET Core" style={{
        "marginRight": "10px"
      }} width="100" height="100" />
  <img src="postgresql.png" alt="PostgreSQL" style={{
        "marginRight": "10px"
      }} width="100" height="100" />
  <br></br>
  Became a part of small team creating an E-Procurement system for PT. Pertamina
  Patra Niaga for them to find the best tender when procuring items.
    </ProjectCard>
    <ProjectCard title="MERY" link="" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  <img src="angular.png" alt="Angular" style={{
        "marginRight": "10px"
      }} width="100" height="100" />
  <img src="csharp.png" alt="C#" style={{
        "marginRight": "10px"
      }} width="100" height="100" />
  <img src="netcore.png" alt=".NET Core" style={{
        "marginRight": "10px"
      }} width="100" height="100" />
  <img src="postgresql.png" alt="PostgreSQL" style={{
        "marginRight": "10px"
      }} width="100" height="100" />
  <br></br>
  Creating Hospital Queuing and Booking system for Bulukumba Hospital. Gives
  ease of access for hospital and patients to manage outpatient's and inpatient
  booking and visits.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      